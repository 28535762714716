<template>
  <div class="IsReading">
    <div v-if="$route.query.examType != 1" class="tips">
      注意：阅卷教师变红，可能是由于该老师长时间没有操作超过15分钟<template
        v-if="userInfo.roleId != 5"
        >，请及时释放该教师所占的试题。</template
      >
    </div>
    <div v-else class="tips tips-all">
      注意：阅卷教师变红，可能是由于该老师长时间没有操作超过15分钟，请及时释放该教师所占的试题。
      <el-checkbox v-model="checked">自动释放失效占用 / 每五分钟</el-checkbox>
    </div>
    <div v-if="$route.query.examType == 1" class="school-item">
      学校筛选：
      <el-select
        v-model="schoolId"
        placeholder="请选择学段"
        clearable
        @change="selectChange"
      >
        <el-option
          v-for="item in schoolList"
          :key="item.schoolId"
          :label="item.schoolName"
          :value="item.schoolId"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="$route.query.examType == 1" class="release-box">
      释放超过
      <el-input
        v-model="minutes"
        placeholder=""
        oninput="value=value.replace(/[^0-9]/g,'')"
        @blur="minutes = $event.target.value"
      ></el-input>
      分钟无操作的阅卷教师占用的试题
      <el-button type="primary" @click="release">批量释放</el-button>
    </div>
    <!-- v-if="showSubject(v)" -->
    <template v-for="(v, i) in monitorMarkingListData">
      <div v-if="showSubject(v)" :key="i" class="paper-list">
        <div class="subject-header">
          {{ v.subjectName }}
        </div>
        <el-table :data="v.questionNumList" border class="tab-list">
          <el-table-column
            prop="questionNum"
            label="题目"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="address" label="教师阅卷情况" align="center">
            <template slot="header">
              教师阅卷情况
              <el-button
                v-if="userInfo.roleId != 5 && v.markType != 2"
                class="set-btn"
                plain
                icon="el-icon-setting"
                @click="clearTeacherList(v)"
                >批量释放</el-button
              >
            </template>
            <template slot-scope="scope">
              <!-- <template > -->
              <span
                v-for="(m, n) in scope.row.teacherList"
                :key="n"
                :class="{ err: setErr(m.getQuestionTime) }"
              >
                {{ m.teacherName }}
                <span :key="listkey">{{ m.getQuestionTime }}</span>
                <i
                  v-if="userInfo.roleId != 5 && v.markType != 2"
                  class="el-icon-delete"
                  @click="releaseQuestion(m, v)"
                ></i>
              </span>
              <!-- </template> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
  </div>
</template>

<script>
import {
  monitorMarkingList,
  monitorRemoveMarking,
  removeMarkingList,
} from "@/core/api/exam/paperMonitor";
import { showSubject } from "@/core/util/util";
import { mapGetters } from "vuex";
import { getStore } from "@/core/util/store";
//
export default {
  name: "IsReading",
  filters: {
    getTime(val, item, that) {
      // console.log(item);
      let date = new Date(val).getTime();
      let nowDate = new Date().getTime();
      let difference = nowDate - date;
      let minute = parseInt(difference / 1000 / 60);
      let second = parseInt((difference / 1000) % 60);
      // console.log(that);
      if (that.checked) {
        if (minute >= 5) {
          this.removeMarkingList(item, 1);
        }
      }
      return minute + "分" + second + "秒";
    },
  },

  data() {
    return {
      this_: this,
      monitorMarkingListData: [],
      listkey: 1,
      minutes: "",
      checked: false,
      schoolId: "",
      schoolList: [],
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters(["GET_SCHOOL_LIST"]),
  },
  watch: {
    GET_SCHOOL_LIST: {
      deep: true,
      handler() {
        this.schoolList = this.GET_SCHOOL_LIST;
        if (this.schoolList.length > 0) {
          this.schoolId = "-1";
        }
      },
    },
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
    this.monitorMarkingList();
    this.schoolList = this.GET_SCHOOL_LIST;
    if (this.schoolList.length > 0) {
      this.schoolId = "-1";
    }
  },
  destroyed() {
    // clearInterval(this.getTime);
    // clearInterval(this.IntervalKey);
  },
  methods: {
    release() {
      if (!this.minutes) {
        this.$message({
          type: "warning",
          message: "请输入需要释放的时间",
        });
        return;
      }
      // if(this.monitorMarkingListData)
      this.monitorMarkingListData.map((item) => {
        if (!item.teacherList || item.teacherList.length == 0) {
          return;
        }
        item.teacherList.map((index) => {
          let date = new Date(index.getQuestionTime).getTime();
          let nowDate = new Date().getTime();
          let difference = nowDate - date;
          let minute = parseInt(difference / 1000 / 60);
          if (minute >= this.minutes) {
            this.removeMarkingList(item, 1);
          }
        });
      });
    },
    selectChange() {
      this.monitorMarkingList();
    },
    showSubject(v) {
      return showSubject(v, 9);
    },
    releaseQuestion(item) {
      // console.log(item);
      this.$confirm(`确定释放${item.teacherName}批阅的试题？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.monitorRemoveMarking(item);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 单个释放
    monitorRemoveMarking(data) {
      data.isWeb = 1;
      monitorRemoveMarking(data).then(() => {
        this.$message({
          type: "success",
          message: "释放成功!",
        });
        this.monitorMarkingList();
      });
    },
    clearTeacherList(item) {
      // console.log(item);
      let data = {
        examPaperId: item.examPaperId,
      };
      this.$confirm(`确定释放${item.subjectName}批阅的试题？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.removeMarkingList(data);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 批量释放
    removeMarkingList(data, type) {
      removeMarkingList(data).then(() => {
        if (!type) {
          this.$message({
            type: "success",
            message: "释放成功!",
          });
        }
        this.monitorMarkingList();
      });
    },
    setErr(val) {
      let type = false;
      let date = new Date(val).getTime();
      let nowDate = new Date().getTime();
      let difference = nowDate - date;
      let minute = parseInt(difference / 1000 / 60);
      if (minute > 15) {
        type = true;
      }
      return type;
    },
    monitorMarkingList() {
      if (
        this.$route.name != "IsReading" &&
        this.$route.name != "unionIsReading"
      ) {
        return;
      }
      let data = {
        examId: this.$route.query.examId,
      };
      if (this.$route.query.examType == 1) {
        if (this.$route.name != "IsReading") {
          data.schoolId = this.schoolId;
        } else {
          data.schoolId = getStore({ name: "school_id" });
        }
      }

      monitorMarkingList(data)
        .then((res) => {
          // console.log(res);
          this.monitorMarkingListData = res.data.data;
          this.IntervalKey = setInterval(() => {
            this.listkey = new Date().getTime();
          }, 1000);
          setTimeout(() => {
            this.monitorMarkingList();
          }, 10000);
        })
        .catch(() => {
          // clearInterval(this.getTime);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.IsReading {
  padding: 24px;
  .tab-list {
    width: 100%;
    margin-top: 24px;
    .el-icon-delete {
      color: #ff2b00;
      cursor: pointer;
    }
    .err {
      color: #ff2b00;
    }
  }
  .set-btn {
    color: #d8d8d8;
    padding: 5px 8px;
  }
  ::v-deep .el-table__header-wrapper {
    .cell {
      color: #161817;
    }
  }
  .paper-list {
    padding: 24px 0;
    border-bottom: 1px dashed #e4e4e4;
    .subject-header {
      font-weight: 500;
      color: #161817;
      position: relative;

      &::after {
        content: "";
        width: 4px;
        height: 12px;
        background: $primary-color;
        position: absolute;
        left: -8px;
        top: 4px;
        border-radius: 2px;
      }
    }
  }
  .school-item {
    margin: 24px 0;
  }
  .release-box {
    .el-input {
      width: 60px;
    }
  }
  .tips-all.tips {
    background: #fff3f3;
    border: 1px dashed #d92020;
    .el-checkbox {
      margin-bottom: 0;
    }
    ::v-deep .el-checkbox.is-checked {
      .el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #d92020;
        border-color: #d92020;
      }
      .el-checkbox__label {
        color: #737677;
      }
    }
    &::after {
      background: #d92020;
    }
  }
  .tips {
    padding: 13px 36px;
    position: relative;
    background: #f2f8fe;
    border: 1px dashed #2474ed;
    color: #737677;

    &::after {
      position: absolute;
      background: #2474ed;
      border-radius: 50%;
      color: #ffffff;
      content: "!";
      letter-spacing: 0;
      left: 16px;
      top: 16px;
      font-size: 12px;
      width: 13px;
      height: 13px;
      line-height: 13px;
      text-align: center;
    }
  }
}
</style>
